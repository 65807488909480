import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// import HomeView from "../views/HomeView.vue";
// import SpaceControllerView from "../views/SpaceControllerView.vue";
// import UserLogin from "../views/UserLogin";
// import UserSignup from "../views/UserSignup";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "menu",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/agenda",
    name: "agenda",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/TermsAndConditions.vue"),
  },
  {
    path: "/inactivity",
    name: "inactivity",
    component: () => import("../views/InactivityScreen.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/UserLogin"),
    beforeEnter(to, from, next){
      // Deshabilitado si está logueado
      if(store.state.user && store.state.user.profile.id){
        return;
      }else{
        next()
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => undefined,
    beforeEnter(){
      // Deshabilitado si está logueado
      console.log("logout")
      localStorage.clear()
      router.push("/")
    },
  },
  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/UserSignup"),
    beforeEnter(to, from, next){
      // Deshabilitado si está logueado
      if(store.state.user && store.state.user.profile.id){
        return;
      }else{
        next()
      }
    }

    
  },
  {
    path: "/spaces",
    name: "spaces",
    component: () =>
      import(/* webpackChunkName: "screens" */ "../views/SpacesView.vue"),
  },
  {
    path: "/duplicate",
    name: "duplicate",
    component: () => import("../views/DuplicateConnection"),
  },
  {
    path: "/duplicatescreen",
    name: "duplicatescreen",
    component: () => import("../views/DuplicateScreen")
  },
  {
    path: "/share/video/:id",
    name: "shareVideo",
    component: () => import("../views/UserShareVideo")
  },
  {
    path: "/spaces/:slug",
    name: "spaceScreen",
    component: () =>
      import(/* webpackChunkName: "screens" */ "../views/SpaceScreenView.vue"),
  },
  {
    path: "/qr/:slug",
    name: "printQR",
    component: () =>
      import(/* webpackChunkName: "screens" */ "../views/PrintQR.vue"),
  },
  {
    path: "/:slug",
    name: "spaceController",
    component: () => import("../views/SpaceControllerView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("SET_ROUTE", {to, from});
  if(store.hasModule('user')){
    const goingto = to.name == "spaceController" ? to.path.replace("/", "") : to.name;
    console.log(goingto)
    store.commit("user/SetSpace", {space: goingto})
  }
  next()
});

export default router;
