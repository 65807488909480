<template>
  <div id="app" :style="$store.state.mode == 'player' && { '--userColor': '#' + $store.state.user.profile.color }">
    <!-- Este template hostea tanto la interfaz mobil como las screens -->
    <b-loading v-model="$store.state.loading">
      <div class="loading-screen fullHeight">
        <svg
          width="90"
          height="90"
          viewBox="0 0 90 90"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M90 45C90 20.1472 69.8528 0 45 0C20.1472 0 0 20.1472 0 45C0 69.8528 20.1472 90 45 90C69.8528 90 90 69.8528 90 45ZM13.5 45C13.5 27.603 27.603 13.5 45 13.5C62.397 13.5 76.5 27.603 76.5 45C76.5 62.397 62.397 76.5 45 76.5C27.603 76.5 13.5 62.397 13.5 45Z"
            fill="var(--loading-fill)"
          />
          <path
            class="primary"
            d="M90 45C90 50.9095 88.836 56.7611 86.5746 62.2208C84.3131 67.6804 80.9984 72.6412 76.8198 76.8198C72.6412 80.9984 67.6804 84.3131 62.2208 86.5746C56.7611 88.836 50.9095 90 45 90L45 76.5C49.1366 76.5 53.2328 75.6852 57.0545 74.1022C60.8763 72.5192 64.3488 70.1989 67.2739 67.2739C70.1989 64.3488 72.5192 60.8763 74.1022 57.0545C75.6852 53.2328 76.5 49.1366 76.5 45H90Z"
            fill="var(--loading-main)"
          />
        </svg>
      </div>
    </b-loading>

    <template
      v-if="
        $store.state.mode == 'player' &&
        $store.state.setupLoaded &&
        validRouteForHeader
      "
    >
      <user-header></user-header>

      <profile-editor
        v-if="$store.state.user.profile.id"
        :key="$store.state.user.profile.email"
      />
      <profile-verify v-if="$store.state.user.profile.id" />
    </template>

    <transition name="route" mode="out-in">
      <router-view
        v-if="$store.state.user || $store.state.space"
        id="room"
        :class="roomClass()"
      ></router-view>
    </transition>

    <!-- AGENDA  -->
    <!--   <template v-if="validRouteForHeader && footerMenuActiveTab == 2">
      <div id="agenda" class="adamaOverlay panzoom-parent">
        <KeepAlive>
          <component v-bind:is="AgendaComponent"/>
        </KeepAlive>
      </div>
    </template> 

    <template
      v-if="$store.state.mode == 'player' && $store.state.user.profile.id"
    >  --->

    <!-- FOOTER BOTONERA -->

     <!--  <template v-if="$theme == 'ADAMA2023' && validRouteForHeader">
       <footer class="card-footer" id="adamaFooter">
          <a
            class="card-footer-item"
            :class="{ active: footerMenuActiveTab == 0 }"
            @click="footerMenuActiveTab = 0"
          >
            <svg viewBox="0 0 448 512" class="mr-2">
              <path
                d="M171.8 353.1l-8.592 50.06c-1.604 9.051 7.904 15.81 15.92 11.57l44.79-23.71l44.79 23.71c8.018 4.238 17.53-2.633 16.04-11.57l-8.707-50.06l36.32-35.4c6.529-6.297 2.979-17.41-6.072-18.79l-50.06-7.332L233.7 246.2c-4.01-8.133-15.69-8.246-19.7 0L191.6 291.6L141.4 298.9C132.5 300.3 128.9 311.3 135.3 317.7L171.8 353.1zM384 64h-40V24c0-13.25-10.75-24-23.1-24S296 10.75 296 24V64h-144V24C152 10.75 141.3 0 128 0S104 10.75 104 24V64H64C28.65 64 0 92.65 0 128v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V128C448 92.65 419.3 64 384 64zM400 448c0 8.824-7.178 16-16 16H64c-8.822 0-16-7.176-16-16V192h352V448z"
              />
            </svg>
          {{ $t('system.btnEvent') }}
          </a>
           <a class="card-footer-item" :class="{active: footerMenuActiveTab == 1}"  @click="footerMenuActiveTab = 1">
                  <svg viewBox="0 0 576 512" class="mr-2">
                    <path d="M554 33.15L384 95.1L212 35.25c-6.5-2.125-13.19-3.249-20.06-3.249c-6.625 0-13.56 .9995-19.94 3.124L20.12 87.1C8 92.75 0 104.6 0 117.6v346.4c0 10.89 10.85 19.02 22 14.84L192 415.1l172 60.75c13 4.25 27 4.375 40 .125l151.9-52.88C568 419.2 576 407.4 576 394.4V48.01C576 37.12 565.2 28.96 554 33.15zM224 90.37l128 45.25v286l-128-45.25V90.37zM48 417.1V129.1l128-44.62v286.3L48 417.1zM528 382.9l-128 44.63V141.2l128-47.25V382.9z"/>
                  </svg>
                  Mapa
                </a> 
          <a
            class="card-footer-item"
            :class="{ active: footerMenuActiveTab == 2 }"
            @click="footerMenuActiveTab = 2"
          >
            <svg width="16" height="16" viewBox="0 0 16 16" class="mr-2">
              <path
                d="M4.53621 1.98845C4.53621 1.5787 4.53286 1.20325 4.53704 0.82779C4.54122 0.412195 4.74359 0.118686 5.07723 0.0300476C5.59067 -0.106254 6.01295 0.233246 6.028 0.80354C6.03636 1.12297 6.02967 1.44324 6.02967 1.76351C6.02967 1.83124 6.02967 1.89897 6.02967 1.97758H10.5452C10.5452 1.58707 10.5419 1.20659 10.546 0.826954C10.5511 0.411358 10.7534 0.118686 11.0879 0.0308838C11.6022 -0.103746 12.0236 0.235755 12.037 0.806048C12.0462 1.1882 12.0387 1.57034 12.0387 1.98845C12.1808 1.98845 12.3163 1.98677 12.4517 1.98845C12.9568 1.9943 13.4694 1.93995 13.9569 2.12475C14.7045 2.40822 15.1393 2.95176 15.253 3.74114C15.2873 3.98029 15.2923 4.2253 15.2923 4.4678C15.2949 7.59689 15.2949 10.726 15.294 13.8551C15.294 14.9003 14.7187 15.6688 13.7362 15.9264C13.5505 15.9749 13.3523 15.9966 13.16 15.9966C9.91302 16.0008 6.66519 16.0008 3.4182 15.9991C2.38799 15.9991 1.63122 15.433 1.35945 14.453C1.30845 14.2682 1.28587 14.07 1.28503 13.8776C1.28085 10.624 1.28085 7.36944 1.28169 4.11576C1.28169 3.08973 1.85282 2.3154 2.83787 2.07039C3.18824 1.98343 3.56704 2.00852 3.93414 1.99095C4.12647 1.98176 4.32047 1.98928 4.53621 1.98928V1.98845ZM2.77432 5.99305V6.21548C2.77432 8.76675 2.77432 11.3189 2.77432 13.8701C2.77432 14.3434 2.93905 14.5073 3.41569 14.5073C6.66268 14.5073 9.90968 14.5073 13.1558 14.5073C13.6475 14.5073 13.7997 14.3526 13.7997 13.8551C13.7997 11.3105 13.7997 8.76591 13.7997 6.22133C13.7997 6.14774 13.7997 6.07416 13.7997 5.99305H2.77432Z"
              />
              <path
                d="M8.28535 9.49061C7.2426 9.49061 6.19901 9.49144 5.15626 9.49061C4.71307 9.49061 4.41454 9.28741 4.32758 8.93704C4.19964 8.4211 4.54081 8.01219 5.11612 7.99881C5.39374 7.99212 5.6722 7.99798 5.95066 7.99798C7.76523 7.99798 9.58064 7.99798 11.3952 7.99798C11.8476 7.99798 12.1428 8.18612 12.2406 8.53064C12.3895 9.05494 12.0307 9.48726 11.4345 9.48977C10.3842 9.49395 9.33479 9.49061 8.28451 9.49061H8.28535Z"
              />
              <path
                d="M6.80559 11.0016C7.34829 11.0016 7.89015 10.999 8.43285 11.0024C8.85179 11.0049 9.14279 11.2014 9.23729 11.5334C9.38028 12.0343 9.05416 12.4808 8.49557 12.4892C7.35582 12.5059 6.21523 12.5017 5.07464 12.4892C4.74518 12.4858 4.46672 12.347 4.34631 12.0017C4.24512 11.7115 4.31119 11.3578 4.5587 11.1972C4.72929 11.0868 4.95339 11.0191 5.15743 11.0099C5.70598 10.9848 6.25537 11.0016 6.80476 11.0016H6.80559Z"
              />
            </svg>
           {{ $t('system.btnSchedule') }}
          </a>
        </footer>
      </template>
      <template v-else> -->
        <!-- FOOTER -->
        <footer
          v-if="$store.state.mode == 'player' && !$store.state.loading"
          class="app-footer p-1 has-text-centered is-size-7"
          :style="{ borderColor: '#' + $store.state.user.profile.color }"
        >
          <template v-if="$store.state.setup.footer.text">
            <json-rich-text :text="$store.state.setup.footer.text" />
          </template>
          <template v-else>
            © {{ $t('system.footer1') }}
            <a
              href="http://douob.online"
              class="has-text-weight-bold"
              target="_blank"
              >douob.online</a
            >
            {{ $t('system.footer2') }}
            <a
              href="http://verne.studio"
              class="has-text-weight-bold"
              target="_blank"
              >verne.studio</a
            >
          </template>
        </footer>
 <!--       </template>
     </template>  -->
    <!-- Stack buttons -->
    <div v-if="validRouteForHeader" class="buttons-stack">
      <div
        v-if="showReactions"
        id="reactionButtons"
        :class="{ open: reactions.expanded }"
      >
        <div
          v-if="!reactions.expanded"
          class="menu-toggle"
          @click="reactions.expanded = true"
        ></div>
        <div class="menu-line">
          <div
            v-for="i in 5"
            class="btn btn-react"
            @touchstart="sendEmojiReaction(i - 1)"
            @touchend="releaseEmojiReaction(i - 1)"
            :key="`btn-react${i - 1}`"
            :class="{ sending: reactions.sending == i - 1 }"
          >
            <img
              v-if="!reactions.cooldown || reactions.lastSent != i - 1"
              :src="`/assets/reactions/reaction-${i - 1}.png`"
            />
            <img v-else :src="`/assets/reactions/reaction-${i - 1}.webp`" />
          </div>
        </div>
      </div>
      <div
        v-if="$store.getters['user/hasAdminAuthority']"
        id="adminButton"
        class="btn has-background-primary has-text-white-ter"
        @click="$store.commit('admin/showRemote', true)"
      >
        <d-icon icon="FaGear" size="is-small" />
      </div>
      <div
        v-if="$store.getters['user/hasAdminAuthority'] && showTalkingPotins"
        id="talkingPointsButton"
        class="btn has-background-white-bis has-text-primary"
        @click="$store.commit('TalkingPointsShow', room.experience)"
      >
        <d-icon icon="FaComment" size="is-small" />
      </div>
    </div>
    <div
      v-if="reactions.expanded"
      class="reactions-backdrop"
      @click.prevent="reactions.expanded = false"
      @touchstart.prevent="reactions.expanded = false"
    ></div>

    <!-- INVITE EXPERIENCE MODAL -->
    <b-modal v-model="ShowExperienceInviteModal" :can-cancel="false">
      <div class="modal-card">
        <header class="modal-card-head has-background-white-bis">
          <p class="modal-card-title is-size-5">{{$t('system.invitation') }}</p>
        </header>
        <section class="modal-card-body has-background-white-ter">
          <template
            v-if="$store.state.user && $store.state.user.experienceInviteData"
          >
            {{ $store.state.user.experienceInviteData.text }}
          </template>
        </section>
        <footer class="modal-card-foot">
          <b-button
            :label="$t('system.close')"
            type="is-primary"
            size="is-small"
            outlined
            expanded
            @click="$store.commit('user/ExperienceInviteHide')"
          />
          <b-button
            label="Join"
            type="is-primary"
            size="is-small"
            expanded
            @click="$store.dispatch('user/EnterExperience')"
          />
        </footer>
      </div>
    </b-modal>

    <admin-remote
      v-if="
        $store.getters['user/hasAdminAuthority'] && $store.hasModule('admin')
      "
    />

    <b-notification
      v-if="
        !$store.state.disableReconnectingAlert &&
        $store.state.route.fullPath != '/spaces' &&
        $store.state.socketConnectedOnce &&
        !$socket.connected &&
        !$store.state.loading &&
        !$store.state.duplicateConnection
      "
      id="connectionError"
      type="is-warning"
      has-icon
      icon="rotate"
      icon-size="is-small fa-spin"
      role="alert"
      :closable="false"
      :message="$t('notifications.connectionError')"
    />

    <div id="cosaspreload" style="display: none">
      <img
        v-for="(av, i) in $store.state.avatars"
        :key="i"
        :src="av.image.sizes.thumbnail.url"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import gsap from "gsap";
import userState from "@/store/user";
import spaceState from "@/store/space";
import adminState from "@/store/admin";
import JsonRichText from "@/components/JsonRichText.vue";

const whitelabelConfig = require("@/whitelabel.config.js");

export default {
  components: {
    AdminRemote: () => import("@/components/AdminRemote.vue"),
    JsonRichText,
  },
  props: {},
  data() {
    return {
      menuReactions: undefined,
      reactions: {
        sending: null,
        lastSent: null,
        expanded: false,
        cooldown: false,
        cooldownTimer: 1200,
      },
      footerMenuActiveTab: 0,
      loaded: false,
    };
  },
  computed: {
    AgendaComponent() {
      return () => import("./styles/adama2023/AgendaAdama.vue");
    },
    showTalkingPotins() {
      if (this.$route.name == "menu") return false;
      if (!this.room.experience.talkingPoints) return false;
      return true;
    },
    room() {
      return this.$store.getters.playlist.find(
        (x) => x.space.slug == this.$route.params.slug
      );
    },
    ShowExperienceInviteModal() {
      if (!this.$store.state.user) return false;
      if (this.$store.state.user.experienceInviteData == null) return false;
      return this.$store.state.user.experienceInviteShow || false;
    },
    validRouteForHeader() {
      return (
        this.$route.name == "menu" ||
        this.$route.name == "spaceController" ||
        this.$route.name == "agenda"
      );
    },
    hasMobileBackground() {
      return this.$store.state.setup.mobileBackground ? true : false;
    },
    mobileBackground() {
      return this.hasMobileBackground
        ? "background-image: url(" +
            encodeURI(this.$store.state.setup.mobileBackground.url) +
            ")"
        : "";
    },
    showReactions() {
      if (!this.$store.state.user?.profile.id) return false;
      if (!this.$store.state.setup.reactions?.enableReactions) return false;
      if (
        this.$route.name == "menu" &&
        !this.$store.state.setup.reactions?.showReactionsOnMenu
      )
        return false;
      return true;
    },
  },
  beforeEnter(el) {
    el.style.opacity = 0;
  },
  enter(el, done) {
    gsap.to(el, {
      opacity: 1,
      duration: 0.8,
      onComplete: done,
    });
  },
  leave(el, done) {
    gsap.to(el, {
      opacity: 0,
      duration: 0.5,
      onComplete: done,
    });
  },
  methods: {
    Reload() {
      document.location.reload();
    },
    roomClass() {
      return `route_${this.$route.name}`;
    },
    sendEmojiReaction(reaction) {
      this.reactions.sending = reaction;
      this.reactions.lastSent = reaction;

      if (this.reactions.cooldown) return;
 
      let color  =  this.$store.state.user?.profile.color;

      this.$store.dispatch('user/SendReaction', {reaction, type:'emoji', color })

      this.reactions.cooldown = true

      setTimeout(() => {
        this.reactions.cooldown = false;
      }, this.reactions.cooldownTimer);
    },
    releaseEmojiReaction() {
      this.reactions.sending = null;
    },
  },
  sockets: {},
  mounted: async function () {
    if (whitelabelConfig.favicon) {
      const favicon = document.getElementById("favicon");
      favicon.href = whitelabelConfig.favicon;
      console.log("Changing favicon to", whitelabelConfig.favicon);
    }

    console.log("📱 DOUOB FRONTEND");
    this.$store.commit("StartLoading");
    this.$store.dispatch("LoadAvatars");
    await this.$store.dispatch("LoadSetup");
    // TODO juntar LoadAvatars y LoadSetup en un axios.all para descargar en paralelo
    // https://www.storyblok.com/tp/how-to-send-multiple-requests-using-axios

    // const userRoutes = ["menu", "spaceController", "login", "signup"];
    // console.log("this.$route.name", this.$store.state.route)
    const screenRoutes = ["spaceScreen", "spaces", "terms","printQR"];
    if (screenRoutes.includes(this.$store.state.route.name)) {
      // 📺 SCREEN MODE
      console.log("📺 SCREEN MODE");
      if (!this.$store.hasModule("space")) {
        this.$store.registerModule("space", spaceState); // dynamic vuex module
        console.log("Loaded space vuex module");
        // Cache service worker. Screens only
        if ("serviceWorker" in navigator) {
          console.log("🍦👨🏻‍🚀 Registrando cache worker");
          navigator.serviceWorker.register("../CacheServiceWorker.js");
        }
      }
      this.$store.commit("SetClientMode", "screen");
    } else {

      // Player sfx load
      this.$sfxLoad(this.$store.state.setup.sounds,
        [
          'click',
          'negativenotification',
          'positivenotification',
          'invitepushtoexperience'
        ]
      );

      // 🕹 PLAYER MODE
      if (!this.$store.hasModule("user")) {
        console.log("agregando vuex user");
        this.$store.registerModule("user", userState); // dynamic vuex module
      }
      this.$store.commit("SetClientMode", "player");
      await this.$store.commit("user/LoadPlayerEmailToken");
      if (this.$store.state.user.token) {
        await this.$store.dispatch("user/GetPlayer").catch(() => {
          this.$store.dispatch("user/GetPlayer");
        });
        if (this.$store.getters["user/hasAdminAuthority"]) {
          // 👑 ADMIN MODE Store layer
          if (!this.$store.hasModule("admin")) {
            this.$store.registerModule("admin", adminState); // dynamic vuex module
            this.$store.commit("admin/GetRawData");
          }
        }
        this.$socket.client.open();
      } else {
        // MANUAL LOGIN / REGISTER
        this.$store.commit("StopLoading");
        console.log("Usuario no encontrado. A registrarse");
        // await this.$store.commit("user/SetAccountState", 2); // register
        // console.log(this.$router, {query: { redirect: this.$route.path }})
        if (this.$route.name != "signup" && this.$route.name != "login") {
          // const redirect = this.$route.name != "signup" || this.$route.path != "login" ? this.$route.path.replace("/", "") : "";
          this.$router.replace({
            path: "/signup",
            query: { redirect: this.$route.path.replace("/", "") },
          });
        }
      }
    }

    // Timesync
    var Timesync = require("timesync");
    Vue.prototype.$time = Timesync.create({
      server: this.$socket.client,
      interval: 5000,
    });

    this.$time.send = function (socket, data, timeout) {
      //console.log('send', data);
      return new Promise(function (resolve, reject) {
        var timeoutFn = setTimeout(reject, timeout);
        socket.emit("timesync", data, function () {
          clearTimeout(timeoutFn);
          resolve();
        });
      });
    };

    if (this.$store.state.route.path == "/agenda") {
      this.footerMenuActiveTab = 2; // seteo el menu manualmente
    }
  },
};
</script>
